import { Calculator, Offer, TenderStateEnum, Tender, DraftTender } from '@dims/components';
import { Offer5086 } from '@/models/Offer';
import { Tender5086, TenderData5086 } from '@/models/Tender';

export const getTotalPoints = (offer: Offer5086, _tender: Tender5086): number => offer.data.bpqScore ?? 0;

export const tenderStub: DraftTender = {
  data: {
    requestForOfferDone: false,
    specificationDone: false,
    deliveryAgreementDone: false,
  },
  agreementName: 'dummy',
  state: TenderStateEnum.Prepare,
};

/* Expose the agreement specific calculations in a generic way to be used by shared components */
export class Calculator5086 implements Calculator {
  totalCost(offer: Offer5086, _tender: Tender): number {
    return offer.data.tcoCalculationResult?.tcoPrice ?? 0;
  }

  sortByCustomRanking(_offers: Offer[]): Offer[] {
    throw new Error('Custom ranking not supported - Method not implemented.');
  }

  calculatePrice(offer: Offer, _tender: Tender5086): number {
    return offer.contractSum ?? 0;
  }

  sortByPrice(_offers: Offer[], _tender: Tender): Offer[] {
    return _offers.sort(
      (a, b) => (a.contractSum ?? 0)
        - (b.contractSum ?? 0),
    );
  }

  sortBPQBestFirst(offers: Offer5086[], tender: Tender5086) {
    return offers.sort(
      // should be negative if a is the best offer, since we want best first
      // since the lowest price is best, result is price(a) - price(b)
      (a, b) => getTotalPoints(b, tender)
        - getTotalPoints(a, tender),
    );
  }

  /// Check if pointscore is below minimum. Agreement specific rule.
  isScoreBelowConditionalThreshold_BackendCalculation(_offer: Offer5086) {
    // This threshold is not used for 50.86
    return false;
  }

  /// Offers with points below a certain threshold are marked as unconditional. Remove later as described in #13384
  isScoreBelowConditionalThreshold_FrontendCalculation(_offer: Offer, _tender: Tender) {
    // This threshold is not used for 50.86
    return false;
  }

  contractSum(offer: Offer5086, _tender: Tender) {
    return offer.contractSum ?? 0;
  }

  /** Shown in 'Samlet score' column for BPQ evaluation
   * should represent the factor used for ordering the offers */
  bpqScoreText(offer: Offer5086, tender: Tender5086, _conditionalOffers: Offer[]) {
    const totalPoints = getTotalPoints(offer, tender);
    if (totalPoints > 0) return `${totalPoints} point`;
    return 'Kvalitets-score ikke indtastet';
  }
}

export function getCalculator() {
  return new Calculator5086();
}

export const getEvaluationSpan = (
  tenderData: TenderData5086,
  allPrices: number[],
  evaluationSpan: number,
  smallestPrice: number,
): number => {
  let result = evaluationSpan;
  const evaluationSpanExtensionPercentage = (tenderData.bpqPriceEvaluationSpanExtensionPercentage ?? 0) / 100;
  const maxPrice = smallestPrice * (1 + evaluationSpan);
  if (allPrices.filter((p) => p > maxPrice).length > allPrices.length / 2) {
    result += evaluationSpanExtensionPercentage;
    result = getEvaluationSpan(tenderData, allPrices, result, smallestPrice);
  }
  return result;
};

export const qualityRatioPercentage = (tender: Tender5086) => {
  const td = tender.data;
  if (td.bpqRatioPercentage === 'Anden procentdel') {
    return td.bpqCustomWeightingQualityCriteriaPercentage ?? 0;
  }
  return parseFloat(td.bpqRatioPercentage ?? '0');
};
