<template>
  <v-dialog v-model="model" width="1400">
    <v-card v-if="model" class="bg-canvas px-5 pt-10">
      <v-card-text>
        <OfferSpecificationViewer :tender :offer />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          class="mb-4 primary-button"
          @click="closeDialog()"
        >
          Luk
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">

import { Offer, Tender } from '@dims/components';
import OfferSpecificationViewer from './OfferSpecificationViewer.vue';

const emit = defineEmits<{
  close: []
  }>();
defineProps<{
  tender: Tender,
  offer: Offer }>();
const model = defineModel<boolean>();

function closeDialog() {
  model.value = false;
  emit('close');
}

</script>
